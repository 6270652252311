@import "../../css/app.scss";

.cursor {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid white;
    position: fixed;
    z-index: 100000000;
    
    @include mobile {
        display: none;
    }
}
