@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
// font-family: 'Open Sans', sans-serif;

// #192655
// #3876BF
// #E1AA74
// #F3F0CA

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    
}

html {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

body {
    background-color: #060f30;
    color: #F3F0CA;
}

a {
    text-decoration: none;
    color: inherit;
}

section {
    height: 100vh;
    scroll-snap-align: center;
}

@mixin mobile {
    @media (max-width: 390px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 720px) {
        @content;
    }
}

@mixin largetab {
    @media (max-width: 1025px) {
        @content
    }
}


