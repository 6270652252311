@import "../../css/app.scss";


.hero {
    height: calc(100vh - 6.9rem);
    background: linear-gradient(180deg, #060f30, #1b2a5f); 
    position: relative;
    overflow: hidden;
    padding: 0 2rem;



    .hero-wrapper {
        max-width: 1366px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @include tablet {
            flex-direction: column-reverse;
        }
        
        @include mobile {
           flex-direction: column;
           align-items: center;
           justify-content: center;
        }

        .intro-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 40px;
            

            h2 {
                color: #E1AA74;
                font-size: clamp(1.5rem, 2vw, 3rem);
                letter-spacing: 5px;
                
            }
            
            h4 {
                color: #ffffff;
                font-size: clamp(2rem, 3.5vw, 6rem);
                font-weight: 300;

                span {
                    margin: 0 5px;
                    color: orange;
                    font-weight: 600;
                }
            }

            .home-buttons {
                z-index: 100;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                button {
                    padding: 10px 20px;
                    border: 1px solid #E1AA74;
                    border-radius: 10px;
                    background-color: transparent;
                    color: #F3F0CA;
                    cursor: pointer;
                    font-weight: 300;
                    width: 10rem;
                }

                .contact-btn {
                    background-color: #17375d;

                }
            }

            img {
                width: 50px;
            }
        }
    
        .slidingTextContainer {
            position: absolute;
            font-size: 25rem;
            font-weight: 600;
            bottom: -50px;
            white-space: nowrap;
            color: #3e486d1f;
            width: 570%;
        }

        .imageContainer {
            margin: 0;
            padding: 0;
            
            @include tablet {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            @include mobile {
                display: none;
            }

            img {
               width: 100%;

               @include tablet {
                    display: none;
               }
            }
        }
    }
}