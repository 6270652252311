@import '../../css/app.scss';


.projects {
    position: relative;
    height: 100%;
    width: 100%;


    .progress-bar {
    
        position: sticky;
        top: 0;
        left: 0;
        padding: 50px;
        text-align: center;
        color: orange;


        h2 {
            font-size: clamp(2rem, 5vw, 5rem);

            @include mobile {
                // padding-top: calc(100vh - 150px);
                padding-top: 2rem;
            
            }
        }

        .progressBar {
            height: 7px;
            background-color: #ebead6;
            border-radius: 5px;
        }

    }

    // largetab fixes
    //  @media (min-width: 768px) {
    //         .container, .container-md, .container-sm {
    //              max-width: 100vw !important; 
    //         }
    // }

    @media (min-width: 576px) {
        .container, .container-sm {
            max-width: unset; 
        }
    }
    
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        
       
        @include mobile {
            justify-content: space-between;
            
        }

        .wrapper {
            max-width: 1366px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 50px;
            padding-top: 5rem;
            
            @include tablet {
                flex-direction: column;
                justify-content: space-between;
            }

            .image-container {
                flex: 1;
                overflow: hidden;

                @include largetab {
            
                    // width: 400px !important;
                }
        

                @include tablet {
                    flex: 0;
                    width: 70%;
                    margin: auto;
                    padding: 0;
                }

                // Image slider 

                    .imageSlider {
                        .carousel-wrapper {
                            background-color: rgba(0, 200, 236, 0.785);
                            width: 100%;
                            
                            .carouselImg {
                                width: 100%;
                                // height: 100%;
                                object-fit: contain;

    
                                @include tablet {
                                    width: 100%;
                                    z-index: 1;
                                }
                            }
                          
                        }

                        .carousel-control-next-icon {
                            background-image: url(../../assets/other/next-icon.png);
                            width: 3rem;
                            height: 5rem;
                        }
    
                        .carousel-control-prev-icon {
                            background-image: url(../../assets/other/prev-icon.png);
                            width: 3rem;
                            height: 5rem;
                        }
    
                        .carousel-indicators {
                            background-color: rgb(0, 121, 115);
                            width: 100%;
                            margin-left: 0;
                            margin-bottom: 0;
                            padding: 0.5rem 0;
    
                            .carousel-indicators [data-bs-target] {
                                background-color: black;
                            }
                        }
                    }

            }
           
    
            .textContainer {
                flex: 1;
                margin-left: auto;

                // @include largetab {
                //     flex: 0 !important;
                // }

                @include mobile {
                    flex: 0;
                    padding: 0 1.5rem;
                    transform: none !important;
                }

                @include tablet {
                    // z-index: -1;
                    text-align: center;
                }

                h3 {
                    color: orange;
                    font-size: clamp(2rem, 3vw, 3rem);
                    margin-bottom: 1.5rem;
                    text-align: center;
                }

                .icon-wrapper {
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 10px;
                    margin: 0 auto;

                    border-radius: 5px;
                    height: fit-content;
                    width: max-content;
                    margin-bottom: 10px;
                    padding: 5px;

                    @include tablet {
                        margin: 10px auto;
                    }

                    img {
                        width: 35px;
                    }
                }

                p {
                    margin: 0 auto;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    display: -webkit-box;
                    max-width: 90%;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    @include mobile {
                        margin-bottom: 2rem;
                    }
                }

                .projectButtons {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;
                    // width: fit-content;
                    gap: 10px;
                    margin: 0 auto;
                    width: 90%;

                    @include tablet {
                        justify-content: center;
                        margin: auto;
                    }
                    
                    a {
                        width: 40%;
                    }

                    button {
                        background-color: rgba(255, 166, 0, 0.144);
                        border: 1px solid #ff8000;
                        border-radius: 10px;
                        cursor: pointer;

                        color: #F3F0CA;
                        font-weight: 600;
                        padding: 10px 20px;
                        margin-top: 1rem;
                        width: 100%;
                        text-transform: uppercase;
                    }
                }

            }
        }
    }
}


// #192655
// #3876BF
// #E1AA74
// #F3F0CA


