.parallax {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: clamp(3rem, 6vw, 5rem);
        text-transform: uppercase;
    }

    .picture1 {
        background-image: url("../../assets/projectImgs/nepal-1.jpeg");
        background-size: cover;
        background-position: bottom;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.25;
    }

    .picture2 {
        background-image: url("../../assets/projectImgs/nepal-2.jpeg");
        background-size: cover;
        background-position: bottom;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.25;
    }
}