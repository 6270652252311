@import "../../css/app.scss";

.navbar {
    height: 6.9rem;

    .wrapper {
        max-width: 1366px;
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-wrapper {
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 1.2rem;
            
            
            .logo {
                width: 60px;
                height: 40px;
    
                @include tablet {
                    display: none;
                }
            }
        }


        .social {
            width: 50%;
            display: flex;
            gap: 15px;
            justify-content: flex-end;
            margin-right: 15px;
            position: relative;

            @include largetab {
                margin-right: 25px;
            }

             .iconLabel {
                position: absolute;
                top: -28px;
                right: 8px;
                color: orange;
                text-align: left;
                width: 70px;
            }
            
            .social-icon {
                font-size: 20px;
                height: 100%;
            }

            .resume {
                border-radius: 5px;
                fill: rgb(110, 154, 255);
            }

            .linkedin {
                border-radius: 5px;
                color: rgb(69, 149, 202);
            }

            .github {
                font-size: 22px;
                color: rgb(242, 231, 211);
            }
            
        }
    }
}

.navbar.scrolled .social {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
}