@import "../../css/app.scss";

.contact {
    height: 100%;
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    font-family: 'Open Sans', sans-serif;
    padding: 1.5rem;
    overflow: hidden;

    @include tablet {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 6rem;
        text-align: center;
    }

      @include mobile {
        padding-top: 10rem;
    }
    .textContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include mobile {
            gap: 10px;
        }

        h2 {
            font-size: clamp(2.5rem, 3vw, 6rem);
            color: orange;
            line-height: 1;
            max-width: 20ch;

            @include tablet {
                max-width: 20ch;
            }
        }

        h3 {
            font-weight: 600;
            color: #E1AA74;
            font-size: clamp(1.5rem, 3vw, 2rem);

        }

        span {
            color: #F3F0CA;
            font-weight: 300;
        }
    }

    .formContainer {
        flex: 1;
        position: relative;

        @include tablet {
            width: 90%;
        }

        @include mobile {
            width: 100%;
        }

        .phoneSvg {
            position: absolute;
            margin: auto;
            z-index: -1;

            @include tablet {
                width: 100%;
            }

            svg {
                width: 100%;
                margin: auto;
            }
        }

        form {

            display: flex;
            flex-direction: column;
            gap: 20px;

            @include mobile {
                height: 80%;
            }
    
            input, textarea {
                padding: 10px 20px;
                background-color: transparent;
                border: 1px solid #E1AA74;
                font-size: 1.4rem;
                font-weight: 300;
                border-radius: 5px;
                outline: none;
                color: white;
            }

                button {
                    height: 2.5rem;
                    border-radius: 2px;
                    border: none;
                    background-color: orange;
                    color: #192655;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 1rem;
                    cursor: pointer;
                    padding: 10px 20px;

                }
        }

    }
    
}
