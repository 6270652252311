@import "../../css/app.scss";

.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;

    .bg {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 400px;
        height: 100vh;
        background-color: #E1AA74;
        z-index: 1000;

        .links {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            a {
                font-size: 26px;
                font-weight: 600;
                margin-bottom: 2rem;
                padding: 5px 10px;
                width: 50%;
                text-align: center;

            }
        }
    }

    button {
        width: 40px;
        height: 40px;
        top: 28px;
        left: 30px;

        border-radius: 50%;
        position: fixed;
        background-color: transparent;
        border: none;
        cursor: pointer;
        z-index: 1000;

        svg {
            margin-top: 10px;
            .path-1 {
                margin-bottom: 10px;
            }

            .path-2 {
                margin-bottom: 10px;
            }
        }
    }
}

.sidebar.open {
    z-index: 1000;
}

// TODO: FIX PROJECTS BUTTONS NOT BEING CLICKED BECAUSE OF .BG Z-INDE

// .sidebar.close .bg {
//     width: 20%;
// }
