@import "../../css/app.scss";

#About {
    height: 100vh;

    @include tablet {
        height: fit-content !important;
        min-height: 100vh;
    }
}
.about {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3rem;
    max-width: 1366px;
    margin: auto;
    
    .textContainer {
        display: flex;
        align-items: center;
        gap: 20px;
        align-self: flex-end;
        margin-bottom: 5rem;
        
        @include tablet {
            padding-top: 3rem;
        }

        @include mobile {
            margin-top: 1rem;
            align-self: left;
            text-align: center;
            flex-direction: column;
            flex: 0;
            margin-bottom: 2rem;
            
        }

        hr {
            width: 500px;
            border: none;
            border-top: 1px solid gray;

            @include tablet {
                width: 250px;
            }
            
            @include mobile {
                width: 100%;
            }
        }

        p {
           font-weight: 300;
           font-size: 20px; 
           text-align: right;
           margin-left: 1rem;

            @include mobile {
                font-size: 1rem; 
                margin-right: 1rem;

            }
        }
    }


    .tab-container {
        width: calc(100% - 2rem);
        margin: 0 1rem;
        margin-bottom: 1rem;
        gap: 5px;

        .nav-link {
            color: rgb(181, 123, 16);
            
            &.active {
                color: rgb(5, 0, 0);
                background: rgb(207, 245, 252);
            }
        }
    }

    .tab-content {
        width: calc(100% - 2rem);
        margin: 0 1rem;
        height: 100%;
        padding-bottom: 3rem;
   
        .flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
    
        }
    
        .MuiCard-root {
            width: 15rem;
            flex:auto;
        }
    }

}
